import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Page } from "../components/layout"
import SEO from "../components/seo"
import ProjectList from "../components/project-list"

class AlexiaProtsenkoPage extends React.Component {
  render() {
    return (
      <Page theme={`silver`} name={"alexia list"}>
        <SEO title="Architecture - Alexia Protsenko" />
        <div className="page__header">
          <h1 className={"h__title"}>Pro and&nbsp;co</h1>
          <h2 className={"h__title h__title--secondary"}>Architecture</h2>
        </div>
        <StaticQuery
          query={graphql`
            query AlexiaProjects {
              allProjectsJson(
                sort: { order: ASC, fields: rank }
                filter: { group: { eq: "alexia" } }
              ) {
                totalCount
                edges {
                  node {
                    title
                    listing_fields {
                      title
                      value
                    }
                    slug
                  }
                }
              }
              images: allFile(
                filter: {relativePath: {regex: "/sections/Architecture/"}}
                sort: {order: ASC, fields: name}
              ) {
                edges {
                  node {
                    id
                    name
                    absolutePath
                    ext
                    relativePath
                    childrenImageSharp {
                      gatsbyImageData(
                        width:275,
                        layout: CONSTRAINED,
                        placeholder: NONE,
                        outputPixelDensities: [1,2,3] 
                      )
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <ProjectList
              cat={"alexia"}
              projects={data.allProjectsJson.edges.map(node => node.node).map((p,index)=>{
                p.image = data.images.edges[index].node.childrenImageSharp[0];
                return p;
              })}
            />
          )}
        />
      </Page>
    )
  }
}

export default AlexiaProtsenkoPage
