import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const CreditsSidebar = () => {

  return (
    <footer className={"f"}>
      <nav>
        <a href={"mailto:contact@pro-and-co.fr"}>contact@pro-and-co.fr</a>
        <Link to={"/mentions-legales-credits"}>crédits</Link>
      </nav>
    </footer>
  )
}

CreditsSidebar.propTypes = {
  siteTitle: PropTypes.string,
}

CreditsSidebar.defaultProps = {
  siteTitle: ``,
}

export default CreditsSidebar
