import React from "react"

import { Page } from "../components/layout"
import SEO from "../components/seo"
import ProjectList from "../components/project-list"
import { graphql, StaticQuery } from "gatsby"

class MichelProtsenkoPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { single: false }
  }

  render() {
    return (
      <Page theme={`bronze`} name={"list"}>
        <SEO title="Urbanisme - Michel Protsenko" />
        <div className="page__header">
          <h1 className={"h__title"}>Pro and&nbsp;co</h1>
          <h2 className={"h__title h__title--secondary"}>Urbanisme</h2>
        </div>
        <StaticQuery
          query={graphql`
            query MichelProjects {
              allProjectsJson(
                sort: { order: ASC, fields: rank }
                filter: { group: { eq: "michel" } }
              ) {
                totalCount
                edges {
                  node {
                    title
                    listing_fields {
                      title
                      value
                    }
                    slug
                  }
                }
              }
              images: allFile(
                filter: {relativePath: {regex: "/sections/Urbanisme/"}}
                sort: {order: ASC, fields: name}
              ) {
                edges {
                  node {
                    id
                    name
                    absolutePath
                    ext
                    relativePath
                    childrenImageSharp {
                      gatsbyImageData(
                        width:275,
                        layout: CONSTRAINED,
                        placeholder: NONE,
                        outputPixelDensities: [1,2,3] 
                      )
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <ProjectList
              cat={"michel"}
              projects={data.allProjectsJson.edges.map(node => node.node).map((p,index)=>{
                p.image = data.images.edges[index].node.childrenImageSharp[0];
                return p;
              })}
            />
          )}
        />
      </Page>
    )
  }
}

export default MichelProtsenkoPage
