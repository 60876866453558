import React from "react"
import ProjectListItem from "./project-list-item"

const ProjectList = ({ projects }) => (
  <div className="p__list">
    {projects.map((p, k) => (
      <ProjectListItem key={k} project={p} />
    ))}
  </div>
)

export default ProjectList
