import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import logoIntro from "../images/intro-logo-pro-and-co.svg"

const Header = () => {

  const data = useStaticQuery(graphql`
    query IntroImages {
    images: allFile(
      filter: {
        relativePath: {regex: "/intro/.*.jpg/"} 
      }
      sort: {order: ASC, fields: name}
    ) 
    {
      edges {
        node {
          id
          name
          absolutePath
          ext
          relativePath
          childrenImageSharp {
            gatsbyImageData(
              width: 600
              height: 900
              layout: CONSTRAINED
              breakpoints: [375, 1080, 1920]
              outputPixelDensities: [1, 2, 3]
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  } 
  `)

  const [hoverLink, setHoverLink] = useState('none');
  const [introduction, setIntroduction] = useState('intro');
  const [randomImagesX,setRandomImagesX] = useState([]);
  useLayoutEffect(() => {
    if(!randomImagesX.length){
      const allImages = data.images.edges.map(n=>n.node.childrenImageSharp[0]);
      setRandomImagesX(allImages);
      setHoverLink(Math.random()>.5?'michel':'alexia');
    }
  }, [randomImagesX,data]);

  function onLinkOver(e){
      const {href} = e.currentTarget;
      if(/michel/.test(href)){
        setHoverLink('michel');
      }else if(/alexia/.test(href)){
        setHoverLink('alexia');
      }
  }
  function onLinkClick(e){
      setIntroduction('complete');
  }

  return (
    <header className={`h h--${introduction} h--over-${hoverLink}`}>
      <div className={`h__intro`}>
        {randomImagesX.map((image,key)=>{
            const gImage = getImage(image);
            return (
              <div key={key} className={`h__intro__shutter ${key===0?'silver':'bronze'}`}>
                <GatsbyImage alt="" key={key} image={gImage} />
              </div>
            )
        })}
      </div>
      <h1 className={"h__title"}><img src={logoIntro} alt={"Pro and co"} /></h1>
      <Link className="h__switch h__link h__switch--top" to={"/alexia-protsenko"} title={"Alexia Protsenko"} data-first-letter={"A"} onClick={onLinkClick} onMouseOver={onLinkOver} onFocus={onLinkOver} ><span>Architecture</span></Link>
      <nav className="h__nav">
        <Link className="h__link h__about" to={"/a-propos"} title="À propos" onMouseOver={onLinkOver} onClick={onLinkClick} onFocus={onLinkOver} ><span>À propos</span></Link>
      </nav>
      <Link className="h__switch h__link h__switch--bottom" to={"/michel-protsenko"} title={"Michel Protsenko"} data-first-letter={"U"} onClick={onLinkClick} onMouseOver={onLinkOver} onFocus={onLinkOver} ><span>Urbanisme</span></Link>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
