import React from "react"
import { Provider, connect } from "react-redux"
import PropTypes from "prop-types"
import store from "../redux/store"
import { SingleModalProvider } from "../contexts/single-modal-context"

import Header from "./header"
import "../styles/main.scss"
import AlexiaProtsenkoPage from "../pages/alexia-protsenko"
import MichelProtsenkoPage from "../pages/michel-protsenko"
import CreditsSidebar from "./credits-sidebar"

let mainContentLock = false;
let mainContentScroll = 0;
let mainContentEl = null;

class Layout extends React.Component {
  componentDidUpdate() {
    document.querySelector('.site').scrollTop = mainContentScroll || 0;
  }

  render() {
    const self = this,
      { children, theme, pageContext } = self.props,
      { isModal = false, modalParent } = pageContext;
    let mainContent = children,
      modalContent = <></>;
      mainContentLock = isModal
    if (isModal) {
      modalContent = children;
      if(mainContentEl){
        mainContent = mainContentEl;
      }else {
        switch (modalParent) {
          default :
          case "michel-protsenko":
            mainContent = (<MichelProtsenkoPage/>)
            break
          case "alexia-protsenko":
            mainContent = (<AlexiaProtsenkoPage/>)
            break
        }
      }
      mainContentScroll = typeof window === 'undefined' ? 0 : window.scrollY;
    }else{
      mainContentEl = mainContent;
    }
    return (
      <div className={`site theme-${theme} scroll${isModal ? '-lock' : '-ready'}`}>
        <Header/>
        <main className={`site__main`}>{mainContent}</main>
        <CreditsSidebar/>
        {modalContent}
      </div>
    )
  }
}

const pageUpdateScroll = () => {
  if (mainContentScroll > 0 && !mainContentLock) {
    const scrollTarget = [0, mainContentScroll];
    mainContentScroll = 0
    return scrollTarget;
  } else {
    return true
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  modal: PropTypes.bool,
  theme: PropTypes.oneOf(["neutral", "silver", "bronze"]),
}
Layout.defaultProps = {
  modal: false,
  theme: "neutral",
}

const mapLayoutStateToProps = (state, ownProps) => {
  const { theme, modal } = state
  return {
    theme: theme,
    modal: modal,
  }
}

const LayoutConnected = connect(mapLayoutStateToProps)(Layout)

class Page extends React.Component {
  constructor(props) {
    super(props)
    const { theme } = props
    if (theme && theme !== 'neutral' && store.getState().theme !== theme && typeof document !== 'undefined'){
      store.dispatch({ type: "setTheme", theme: theme })
    }
  }

  render() {
    const self = this,
      { children } = self.props
    return children
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(["neutral", "silver", "bronze"]),
}
Page.defaultProps = {
  theme: "neutral",
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const pageWrapper = ({ element, props }) => {
  return <LayoutConnected {...props}>{element}</LayoutConnected>
}

const rootWrapper = ({ element }) => {
  return <Provider store={store}><SingleModalProvider>{element}</SingleModalProvider></Provider>
}

const ssrRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([<div key="___modals" id="___modals" />]);
};

export { Page, pageWrapper, rootWrapper, ssrRenderBody, pageUpdateScroll }
