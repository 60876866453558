import {pageWrapper,rootWrapper,pageUpdateScroll} from "./src/components/layout"

export const wrapRootElement = rootWrapper
export const wrapPageElement = pageWrapper
export const shouldUpdateScroll = pageUpdateScroll

// This only works if in the modal page you add a
// Link to the homepage.
export const onPrefetchPathname = ({ loadPage, pathname }) => {
  //console.log('onPrefetchPathname',window.indexPageData,pathname);
  if (window.indexPageData === undefined) {
    /*loadPage("/").then((result) => {
      window.indexPageData = result;
      console.log('indexPageData',result.page);
      // If we load the modal page directly we need to
      // re-render by changing the state. Otherwise
      // the initial result is null for some reason.
      if (window.setIndexPageData) window.setIndexPageData();
    });*/
  }
};