import { createStore as reduxCreateStore } from "redux"

function reducer(state = "none", action) {
  switch (action.type) {
    case "setTheme":
      return {
        ...state,
        theme: action.theme,
      }
    case "setModal":
      console.log("setModal", action.modal)
      return {
        ...state,
        modal: action.modal,
      }
    default:
      return state
  }
}

const initialState = {
  theme: "neutral",
  modal: false,
}

const createStore = () => reduxCreateStore(reducer, initialState)
const store = createStore()
export default store
