// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-alexia-protsenko-js": () => import("./../../../src/pages/alexia-protsenko.js" /* webpackChunkName: "component---src-pages-alexia-protsenko-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-credits-js": () => import("./../../../src/pages/mentions-legales-credits.js" /* webpackChunkName: "component---src-pages-mentions-legales-credits-js" */),
  "component---src-pages-michel-protsenko-js": () => import("./../../../src/pages/michel-protsenko.js" /* webpackChunkName: "component---src-pages-michel-protsenko-js" */),
  "component---src-templates-page-project-js": () => import("./../../../src/templates/page-project.js" /* webpackChunkName: "component---src-templates-page-project-js" */)
}

