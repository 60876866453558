import React from "react"
import { Link } from "gatsby"
import LineBreakToBr from "./line-break-to-br"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectListItem = ({ project }) => {
  const image =  getImage(project.image)
  return (
  <Link to={`/${project.slug}`} className={`p__list__item`}>
    <div className={`p__list__pict`} >
      <div className="p__list__pict__base" >
        <GatsbyImage image={image} alt={project.title} />
      </div>
      <div className="p__list__pict__hover" >
        <GatsbyImage image={image} alt="" />
      </div>
    </div>
    <div className="p__list__resume">
      {project.listing_fields.map((field, i) => (
        <div key={i}>
          {field.title ? (<div className="title">{field.title}</div>) : ""}
          <div className="value"><LineBreakToBr source={field.value} /></div>
        </div>
      ))}
    </div>
  </Link>
)}

export default ProjectListItem
